<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiLocationListProps {
  contentType?: string | undefined;
  fields: any;
  index?: number | null;
  language?: string | undefined;
  slug?: string | undefined;
}
const props = withDefaults(defineProps<CiLocationListProps>(), {
  contentType: undefined,
  index: null,
  language: undefined,
  slug: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const { $gettext } = useGettext();
const { trackAdClickAndFollow: DTrackAdClickAndFollow, trackAdImpression, impressionId } = useTracking();

/********************
 * REFS & VARS       *
 ********************/
const contexts = computed(() => {
  const contexts = [
    {
      schema: 'iglu:com.camparound/campinginfo_cs_location_list/jsonschema/1-0-0',
      data: {
        headline: props.fields.headline || null,
      },
    },
  ];

  props.fields.locations.forEach((location: any) => {
    contexts.push({
      schema: 'iglu:com.camparound/campinginfo_location/jsonschema/1-0-3',
      data: {
        id: `${location.id}` || null,
        location_type: location.location_type || null,
        name: location.name || null,
        slug: location.slug || null,
      },
    });
  });
  return contexts;
});

/********************
 * FUNCTIONS         *
 ********************/
function elementVisible(isVisible: boolean) {
  if (isVisible) {
    trackAdImpression({
      bannerId: props.contentType,
      impressionId: impressionId.value,
      context: contexts.value,
    });
  }
}

function trackAdClickAndFollow(event, location) {
  DTrackAdClickAndFollow(event, {
    bannerId: props.contentType,
    impressionId: impressionId.value,
    zoneId: 'location_name',
    context: [
      {
        schema: 'iglu:com.camparound/campinginfo_cs_location_list/jsonschema/1-0-0',
        data: {
          headline: props.fields.headline || null,
        },
      },
      {
        schema: 'iglu:com.camparound/campinginfo_location/jsonschema/1-0-3',
        data: {
          id: `${location.id}` || null,
          location_type: location.location_type || null,
          name: location.name || null,
          slug: location.slug || null,
        },
      },
    ],
  });
}

function getRouterLinkForItem(location) {
  switch (location.location_type) {
    case 'country':
      return localePath({
        name: RBN_COUNTRY_SEARCH,
        params: {
          countrySlug: location.slug,
        },
      });
    case 'city':
      return localePath({
        name: RBN_CITY_SEARCH,
        params: {
          citySlug: location.slug,
        },
      });
    case 'region':
      return localePath({
        name: RBN_REGION_SEARCH,
        params: {
          regionSlug: location.slug,
        },
      });
    case 'federal_state':
      return localePath({
        name: RBN_FEDERAL_STATE_SEARCH,
        params: {
          countrySlug: location.country.slug,
          federalStateSlug: location.slug,
        },
      });
    default:
      return localePath('/');
  }
}
</script>

<template>
  <div
    v-once
    :id="props.fields.anchor_id"
    v-observe-visibility="{
      callback: elementVisible,
      once: true,
      throttle: 500,
      intersection: {
        threshold: 0.1,
      },
    }"
    :lang="props.language"
    class="section section--location-list mb-6 mt-12 pb-0 md:container md:mb-12"
  >
    <div>
      <div
        v-if="props.fields.headline"
        class="col-span-12 px-4"
      >
        <h2
          class="m-0 mb-4 text-center"
          v-html="fields.headline"
        />
      </div>
      <CiCssSlider v-if="props.fields.locations?.length" mq="md" inactive-wrapper-classes="grid grid-cols-12 gap-x-[15px]">
        <!-- col-12 col-sm-6 col-md-4 col-lg-3 mb-4 -->
        <div
          v-for="(location, idx) in props.fields.locations"
          :key="idx"
          class="slide col-span-12 mb-4 grow sm:col-span-6 md:col-span-4 lg:col-span-3"
        >
          <nuxt-link
            class="section-country__link group block rounded bg-gray-20 p-0 text-center text-sm text-black transition-colors duration-150 ease-in-out hover:bg-gray-40 hover:no-underline"
            :to="getRouterLinkForItem(location)"
            @click.capture="trackAdClickAndFollow($event, location)"
          >
            <span class="name block truncate p-2 font-bold">{{ location.name }}</span>
            <span
              v-if="location.count_campsites > 0"
              class="count block truncate rounded-b bg-white p-1 text-primary shadow transition-colors duration-150 ease-in-out group-hover:bg-primary group-hover:text-white"
            >{{ $gettext('%{num} campsites', { num: location.count_campsites }) }}</span>
            <span
              v-else
              class="count block truncate rounded-b bg-white p-1 text-primary shadow transition-colors duration-150 ease-in-out group-hover:bg-primary group-hover:text-white"
            >{{ $gettext('Show campsites') }}</span>
          </nuxt-link>
        </div>
      </CiCssSlider>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@media screen(max-md) {
  .slide {
    scroll-snap-align: center;
    min-width: 220px;
  }

  .slide:first-of-type {
    margin-left: 15px;
  }

  .slide:last-of-type {
    margin-right: 15px;
  }
}
</style>
